import React from "react"

const Img = (props) => (
	<img {...props}
	     style={{
		     opacity: 0,
		     transition: 'opacity .5s ease-out'
	     }}
	     onLoad={(e) => (e.target.style.opacity = 1)}/>
)

export default Img