import React from "react"
import useGlobalHook from "use-global-hook"

import * as actions from "../actions"

const initialCartItems = localStorage.getItem('itemsInCart')
export const shipmentId = 489
export const minDeliveryCost = 3000

const initialState = {
	itemsInCart: JSON.parse(initialCartItems ?? '{}'),
	submitAddress: localStorage.getItem('address' ?? ''),
	submitPhone: localStorage.getItem('phone' ?? '')
}

const useGlobal = useGlobalHook(React, initialState, actions)

export default useGlobal