import React, {createRef, useState} from "react"
import useGlobal from "../../store"
import {Link, useLocation} from "@reach/router"
import Img from "./Img"
import TemporaryModal from "../temporaryModal/TemporaryModal";

const Header = () => {
	const menuRef = createRef()
	const [cart] = useGlobal(state => state.itemsInCart)
	const location = useLocation()

	return (
		<header className='page-header'>
			<div className='page-header__wrapper'>
				<Link to='/'
					  onClick={() => window.scrollTo(0, 0)} // setShow (true)
				      className='page-header__logo-link'>
 					<picture className='page-header__logo'>
						<source media='(min-width: 1280px)'
						        srcSet='/img/logo-desktop-@2x.png 2x, /img/logo-desktop@1x.png 1x'/>
						<Img src='/img/logo-mobile@1x.png'
						     srcSet='/img/logo-mobile@2x.png 2x'
						     alt='Логотип «Shipudim»'/>
					</picture>
				</Link>
				{/*{show ? <TemporaryModal /> : null}*/}
				<nav className='page-header__nav' ref={menuRef}>
					<h2 className='page-header__nav-title--mobile'>Карта сайта</h2>
					<button className='page-header__nav-btn--mobile'
					        aria-label='Закрыть меню'
					        onClick={() => menuRef.current.classList.remove('page-header__nav--opened')}/>
					<ul className='page-header__nav-list'
					    onClick={(event) => {
						    if(event.target.tagName === 'A')
							    menuRef.current.classList.remove('page-header__nav--opened')
					    }}>
						<li className='page-header__nav-item'>
							<Link to='/'
								  onClick={() => window.scrollTo(0, 0)} // setShow (true)
							      getProps={({ isCurrent }) =>
								      ({className: isCurrent ? 'page-header__nav-link current-page' : 'page-header__nav-link'})}>
								Главная
							</Link></li>
						<li className='page-header__nav-item'>
							<Link to='/menu'
								  onClick={() => window.scrollTo(0, 0)} // setShow (true)
							      getProps={({ isPartiallyCurrent }) =>
								      ({
												className: isPartiallyCurrent
													? 'page-header__nav-link current-page'
													: 'page-header__nav-link',
												style: isPartiallyCurrent ? {
													pointerEvents: 'none'
												} : null
								      })}>Меню</Link></li>
						<li className='page-header__nav-item'>
							<Link to='/about'
								  onClick={() => window.scrollTo(0, 0)} // setShow (true)
							      getProps={({ isPartiallyCurrent }) =>
								      ({className: isPartiallyCurrent ? 'page-header__nav-link current-page' : 'page-header__nav-link'})}>О нас</Link></li>
						<li className='page-header__nav-item'>
							<Link to='/deals'
								  onClick={() => window.scrollTo(0, 0)} // setShow (true)
							      getProps={({ isPartiallyCurrent }) =>
								      ({className: isPartiallyCurrent ? 'page-header__nav-link current-page' : 'page-header__nav-link'})}>Акции</Link></li>
						<li className='page-header__nav-item'>
							<Link to='/jobs'
								  onClick={() => window.scrollTo(0, 0)} // setShow (true)
							      getProps={({ isPartiallyCurrent }) =>
								      ({className: isPartiallyCurrent ? 'page-header__nav-link current-page' : 'page-header__nav-link'})}>Вакансии</Link></li>
						<li className='page-header__nav-item'>
							<Link to='/legal-information'
								  onClick={() => window.scrollTo(0, 0)} // setShow (true)
							      getProps={({ isPartiallyCurrent }) =>
								      ({className: isPartiallyCurrent ? 'page-header__nav-link current-page' : 'page-header__nav-link'})}>Правовая информация</Link></li>
					</ul>
					<p className='page-header__nav-feedback'>Связаться с нами:
						<a className='page-header__nav-feedback-phone'
						   href='tel:+77774706241'>+7 777 470 62 41</a>
					</p>
				</nav>
				<ul className='page-header__user-block user-block'>
					<li className={`user-block__item user-block__item--shopping-cart${Object.keys(cart).length && !location.pathname.startsWith('/cart/') ? ' shopping-cart-icon--full' : ''}`}>
						<Link className='user-block__item-link'
							  onClick={() => window.scrollTo(0, 0)} // setShow (true)
						      to='/cart/'>
							<Img src={`/img/shopping-cart${location.pathname.startsWith('/cart/') ? '-visited' : ''}.svg`}
							     width='17'
							     height='17'
							     alt='Корзина'/>

						</Link>
					</li>
					<li className='user-block__item user-block__item--phone'>
						<a className='user-block__item-link'
						   href='tel:+77774706241'>
							<Img src='/img/phone.svg'
							     width='17'
							     height='17'
							     alt='Телефон'/>
						</a>
					</li>
					<li className='user-block__item user-block__item--mobile-menu'>
						<button className='user-block__item-link'
						        onClick={() => menuRef.current.classList.add('page-header__nav--opened')}/>
					</li>
				</ul>
			</div>
		</header>
	);
}

export default Header
