import {Link} from "@reach/router"
import React, {useState} from "react"
import Img from "./Img"
import TemporaryModal from "../temporaryModal/TemporaryModal";

const Footer = () => {
	// const [show, setShow] = useState(false);
	return (
		<footer className='page-footer'>
			<div className='page-footer__wrapper'>
				<Link to='/'
					  onClick={() => window.scrollTo(0, 0)} // setShow (true)
					  className='page-footer__logo-link'>
					<picture className='page-footer__logo'>
						<source media='(min-width: 1280px)'
								srcSet='/img/logo-desktop-@2x.png 2x, /img/logo-desktop@1x.png 1x'/>
						<Img src='/img/logo-mobile@1x.png'
							 srcSet='/img/logo-mobile@2x.png 2x'
							 alt='Логотип «Shipudim»'/>
					</picture>
				</Link>
				{/*{show ? <TemporaryModal /> : null}*/}
				<nav className='page-footer__nav'>
					<ul className='page-footer__nav-list'>
						<li className='page-footer__nav-item'>
							<Link to='/'
								  onClick={() => window.scrollTo(0, 0)}
								  getProps={({isCurrent}) =>
									  ({className: isCurrent ? 'page-header__nav-link current-page' : 'page-header__nav-link'})}>Главная</Link>
						</li>
						<li className='page-footer__nav-item'>
							<Link to='/menu'
								  onClick={() => window.scrollTo(0, 0)} // setShow (true)
								  getProps={({isPartiallyCurrent}) =>
									  ({className: isPartiallyCurrent ? 'page-header__nav-link current-page' : 'page-header__nav-link'})}>Меню</Link>
						</li>
						<li className='page-footer__nav-item'>
							<Link to='/about'
								  onClick={() => window.scrollTo(0, 0)} // setShow (true)
								  getProps={({isPartiallyCurrent}) =>
									  ({className: isPartiallyCurrent ? 'page-header__nav-link current-page' : 'page-header__nav-link'})}>О
								нас</Link>
						</li>
						<li className='page-footer__nav-item'>
							<Link to='/deals'
								  onClick={() => window.scrollTo(0, 0)} // setShow (true)
								  getProps={({isPartiallyCurrent}) =>
									  ({className: isPartiallyCurrent ? 'page-header__nav-link current-page' : 'page-header__nav-link'})}>Акции</Link>
						</li>
						<li className='page-footer__nav-item'>
							<Link to='/jobs'
								  onClick={() => window.scrollTo(0, 0)} // setShow (true)
								  getProps={({isPartiallyCurrent}) =>
									  ({className: isPartiallyCurrent ? 'page-header__nav-link current-page' : 'page-header__nav-link'})}>Вакансии</Link>
						</li>
					</ul>
				</nav>
				<div className='page-footer__partners'>
					<a className='page-footer__partner-link'
					   href='https://hinkalnaya.kz/'
					   target='_blank'
					   rel="noopener noreferrer">
						<picture className='page-footer__partner-logo'>
							<source media='(min-width: 768px)'
									srcSet='/img/hh-logo-desktop@2x.png 2x, /img/hh-logo-desktop@1x.png 1x'/>
							<Img src='/img/hh-logo-mobile@1x.png'
								 srcSet='/img/hh-logo-mobile@2x.png 2x'
								 alt='Логотип «Shipudim»'/>
						</picture>
					</a>
					<a className='page-footer__partner-link'
					   href='https://narsharab.kz/'
					   target='_blank'
					   rel="noopener noreferrer">
						<picture className='page-footer__partner-logo'>
							<source media='(min-width: 768px)'
									srcSet='/img/narsharab-desktop@2x.png 2x, /img/narsharab-desktop@1x.png 1x'/>
							<Img src='/img/narsharab-mobile@1x.png'
								 srcSet='/img/narsharab-mobile@2x.png 2x'
								 alt='Логотип «Shipudim»'/>
						</picture>
					</a>
				</div>
			</div>
			<div className="footer__developer-copyright">
				<a className="footer__developer-copyright-link" href="https://doovertech.com/ru" target="_blank">
					<div className="footer__copyright-dev-icon">
						<svg width="24" height="12" fill="none">
							<path
								d="M13.6.236a1.161 1.161 0 00-1.429.808l-2.58 9.29a1.161 1.161 0 102.237.622l2.581-9.29a1.161 1.161 0 00-.808-1.43zM5.775.463a1.161 1.161 0 00-1.636.15L.27 5.268A1.161 1.161 0 00.27 6.755l3.87 4.635a1.159 1.159 0 001.637.147 1.161 1.161 0 00.147-1.636L2.674 6.01l3.251-3.91a1.161 1.161 0 00-.15-1.636zM23.73 5.246L19.86.61A1.161 1.161 0 1018.076 2.1l3.25 3.892-3.252 3.911a1.161 1.161 0 101.786 1.485l3.87-4.655a1.161 1.161 0 000-1.487z"
								fill="#000"/>
						</svg>
					</div>
					<div className="footer__copyright-dev-by">
						<svg width="19" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M8.472 6.128c.235.533.352 1.12.352 1.76 0 .64-.117 1.227-.352 1.76a4.122 4.122 0 01-2.256 2.272 4.186 4.186 0 01-1.584.304c-.608 0-1.136-.101-1.584-.304-.437-.213-.725-.475-.864-.784V12H.216V.352h2.032v4.272c.139-.31.421-.565.848-.768.437-.213.96-.32 1.568-.32.544 0 1.067.107 1.568.32a4.17 4.17 0 012.24 2.272zM6.6 8.768c.117-.267.176-.56.176-.88 0-.32-.059-.613-.176-.88a1.96 1.96 0 00-.496-.704 2.107 2.107 0 00-.752-.448 2.746 2.746 0 00-.96-.16c-.512 0-.965.107-1.36.32-.395.203-.656.459-.784.768v2.192c.128.31.39.57.784.784.395.203.848.304 1.36.304.352 0 .672-.053.96-.16.299-.107.55-.256.752-.448.213-.192.379-.421.496-.688zM9.506 3.76h2.192l2.16 5.12 2.16-5.12h2.256l-4.928 11.648h-2.224l1.68-3.92L9.506 3.76z"
								fill="#000"/>
						</svg>
					</div>
					<div className="footer__developer-logo-wrapper">
						<picture>
							<source media="(min-width: 768px)"
									srcSet="/img/doover-logo-desktop@1x.png 1x, /img/doover-logo-desktop@2x.png 2x"/>
							<Img className="footer__developer-logo"
								 src="/img/doover-logo-mobile@1x.png"
								 srcSet="/img/doover-logo-mobile@2x.png 2x" alt=""/>
						</picture>
					</div>
				</a>
			</div>
		</footer>
	)
}

export default Footer
