import {shipmentId} from "../store";

export const addItem = (store, item) => {
	const oldAmount = store.state.itemsInCart[item.id]
	const newAmount = (oldAmount ? oldAmount : 0) + item.amount
	let itemsInCart = {
		...store.state.itemsInCart,
		[item.id]: newAmount,
		[shipmentId]: 0
	}

	if (newAmount <= 0) {
		itemsInCart = Object.fromEntries(Object.entries(itemsInCart).filter(([, amount]) => amount > 0))
	}

	if (Object.keys(itemsInCart).length)
		itemsInCart = {
			...itemsInCart,
			[shipmentId]: 1
		}

	localStorage.setItem('itemsInCart', JSON.stringify(itemsInCart))

	store.setState({ itemsInCart })
}

export const setSubmitAddress = (store, address) => {
	localStorage.setItem('address', address)

	store.setState({ address })
}

export const setSubmitPhone = (store, phone) => {
	if (store.state.submitPhone !== phone) {
		let num = phone.replace(/[^0-9]/g, '')

		num = `+7 (${num.substr(1, 3)}) ${num.substr(4, 3)} ${num.substr(7, 2)} ${num.substr(9, 2)}`
			.replace(/[^0-9]+$/g, '')

		localStorage.setItem('phone', num)

		store.setState({ submitPhone: num })
	}
}